import { gtmTrack } from './google-tracking';
import { sha256, isEmailProfessional } from './utils';

function trackAddTemplate(name: string) {
  return gtmTrack({
    event: 'add_template',
    name,
  });
}

function trackNewPresentation(seriesId?: string) {
  return gtmTrack({
    event: 'series_activated',
    type: 'create',
    series_id: seriesId,
  });
}
async function trackFirstPresentation(seriesId?: string, email?: string) {
  return gtmTrack({
    event: 'series_activated',
    type: 'first_create',
    sha256_email_address: email && (await sha256(email)),
    series_id: seriesId,
  });
}
function trackPlayPresentation(seriesId?: string) {
  return gtmTrack({
    event: 'series_activated',
    type: 'present',
    series_id: seriesId,
  });
}

function trackWebinar(name: string) {
  return gtmTrack({
    event: 'registration_webinar',
    name,
  });
}

function trackEnterpriseForm(type: string, form: string, placement?: string) {
  return gtmTrack({
    event: 'form_interaction',
    form,
    type,
    placement,
  });
}

async function trackSignup(referral?: string, email?: string) {
  return gtmTrack({
    event: 'sign_up',
    referral,
    sha256_email_address: email && (await sha256(email)),
    email_domain: isEmailProfessional(email),
  });
}

export function trackGenerateLead(placement?: string, email?: string) {
  return gtmTrack({
    event: 'generate_lead',
    placement,
    email_domain: isEmailProfessional(email),
  });
}

interface PageViewData {
  page_location: string | undefined;
  page_title: string | undefined;
  country: string | null;
  language: string | null;
  page_type: string | undefined;
  logged_in: boolean;
  plan_type: string | undefined;
  user_purpose: string | undefined;
}

export function trackPageView(data: PageViewData, legacy = false) {
  if (legacy) {
    return;
  }

  return gtmTrack({
    event: 'pageview',
    ...data,
  });
}

export const TrackingMethods = {
  trackAddTemplate,
  trackNewPresentation,
  trackFirstPresentation,
  trackPlayPresentation,
  trackWebinar,
  trackEnterpriseForm,
  trackGenerateLead,
  trackSignup,
  trackPageView,
};
