import Cookies from '@mentimeter/cookies';
import type { UtmParams } from './types';
import { LAST_TOUCH_KEY, FIRST_TOUCH_KEY } from './utmKeys';

export const getUtmParamsFromCookies = (): UtmParams | null => {
  const lastParams = Cookies.getJSON(LAST_TOUCH_KEY);
  const firstParams = Cookies.getJSON(FIRST_TOUCH_KEY);
  return lastParams && firstParams
    ? {
        [LAST_TOUCH_KEY]: lastParams,
        [FIRST_TOUCH_KEY]: firstParams,
      }
    : null;
};
