import { education, domains, hipoAccounts } from './email-constants';
export async function sha256(email: string) {
  if (typeof window === 'undefined') return;
  // Convert the email string to an ArrayBuffer
  const data = new TextEncoder().encode(email);

  // Use the Web Crypto API to calculate the SHA-256 hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert the hash ArrayBuffer to a hex string
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
}

export function isEmailProfessional(email?: string) {
  if (!email) return 'no_email';

  const isEmailHipo = hipoAccounts.some((domain) => email.includes(domain));
  if (isEmailHipo) return 'hipo_5';

  const isEmailEducation = education.some((word) => email.includes(word));
  const isEmailKnownDomains = domains.some((domain) => email.includes(domain));
  if (isEmailEducation || isEmailKnownDomains) return 'non_professional';
  return 'professional';
}
